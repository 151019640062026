 <template>
<div class="professional-form">
  <spinner
    :active="isLoading"
    />
  <layout-container class="content">
    
    
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-4">
        <regular-button class="button back" @click="routerBack"> 
          <font-awesome-icon icon="chevron-left" />Retour 
        </regular-button>
      </div>
      <div class="cell small-12 medium-5 professional-name">
        <h2 v-if="proFormData.professional.name">
          {{ proFormData.professional.name }}
        </h2>
        <h2 v-else>
          Création de la fiche
        </h2>
        
      </div>
      <div class="cell small-12 medium-3 text-right" v-if="proFormData.professional.pk">
        <action-link label="Voir la fiche publique" icon="eye" icon-position="left" v-on:click="openProfessionalInNewTab"/>
      </div>
    </div>
    
    
    <panels 
      v-if="proFormData != null"
      withSelectors
      name="formNewPanels"
      ref="formNewPanels"
      :withEndButton="false"
      :withNavigationButtons="false"
      :doNotValidatePanel="true"
      v-on:active-panel="panelChanged"
      >
      
      <panel
        id="panel-0"
        name="SUIVI"
        :withTitle="false"
        v-if="!isProfessional && proFormData.professional.pk"
        >
        <div>
          
          <div class="grid-x grid-margin-x">
            
            <div class="cell small-12">
              <section class="admin-panel">
                <h2>Récapitulatif des éléments publiés</h2>
                
                <table class="states">
                  <thead>
                    <tr>
                      <th>Objet</th>
                      <th>État</th>
                      <th>Étape du parcours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="professional">
                      <td>Fiche</td>
                      <td class="capitalize">{{ proFormData.professional.publicationStatusAsLabel }}</td>
                      <td :class="proFormData.professional.transitionState">{{ proFormData.professional.statusAsLabel }}</td>
                    </tr>
                    <tr class="presentation">
                      <td>Présentation</td>
                      <td class="capitalize">{{ proFormData.presentation.publicationStatusAsLabel }}</td>
                      <td :class="proFormData.presentation.transitionState">{{ proFormData.presentation.statusAsLabel }}</td>
                    </tr>
                    <tr class="references">
                      <td>Références</td>
                      <td class="capitalize"><div v-for="(val,key) in referencesLabels" :key="key">{{key}} : {{ val.count}}</div></td>
                      <td><div v-for="(val,key) in referencesStatus" :key="key" :class="val.state">{{key}} : {{ val.count}}</div></td>
                    </tr>
                  </tbody>
                </table>
                
              </section>
              
            </div>
            
            
            <div class="cell small-12 medium-8">
              
              <section class="admin-panel">
                
                <select-referents
                  label="Référents :"
                  v-model="proFormData.professional.referents"
                  :multiple="true"
                  />
                
              </section>
              
            </div>
            
            <div class="cell small-12 medium-12">
              <section class="admin-panel">
                <h2>Configurations spéciales</h2>

                <div class="grid-x grid-margin-x">
                
                    <div class="cell small-12 medium-7">
                      <div class="admin-controls">
                        <checkbox-field
                          v-model="proFormData.professional.subscribedToNewsletter"
                          id="subscribedToNewsletter"
                          label="Souhaite recevoir la newsletter"
                          />
                        
                        <checkbox-field
                          v-show="showBapConfig"
                          v-model="proFormData.professional.allowToRegisterToBap"
                          id="allowToRegisterToBap"
                          label="Permettre l'accès à la BAP"
                          />
                        <checkbox-field
                          v-model="proFormData.professional.canUseMultipleAddresses"
                          id="canUseMultipleAddresses"
                          label="Permettre l'utilisation d'adresses géographiques multiples pour une fiche (ex: Doremi)"
                          />
                        
                        <checkbox-field
                          v-model="proFormData.professional.isMonAccompagnateurRenov"
                          id="isMonAccompagnateurRenov"
                          label="Est Mon Accompagnateur Renov"
                          />
                        
                        
                      </div>
                    </div>
                    
                    <div class="cell small-12 medium-5">
                      <newsletters-recipient
                        id="emails"
                        :is-error="proFormErrors.value.emails"
                        ref="newslettersRecipient"
                        v-show="proFormData.professional.subscribedToNewsletter"
                        v-model="proFormData.newslettersRecipients"
                        />
                    </div>

                </div>
                
              </section>
            </div>
            
          </div>
          
          <section class="admin-panel">
            <h2>Notes</h2>
            <note
              v-model="proFormData.notes"
              :professional-pk="proFormData.professional.pk"
              v-on:reload-notes="load"
              />
          </section>


          <div class="grid-x grid-margin-x">
            <div class="cell small-12 medium-8">

          
          <section class="admin-panel">
            <h2>Historique de validation</h2>
            <history v-model="proFormData.professional.transitionHistory['history']" workflow="publication" :state="proFormData.professional.transitionState"/>

          </section>
          
          </div>
          
            <div class="cell small-12 medium-4">
          
          <section class="admin-panel">
            <h2>Actions</h2>
            
            <trigger-buttons
              object-type="professional"
              :pk="proFormData.professional.pk"
              :triggers="proFormData.professional.triggers"
              @trigger-clicked="saveProfessional"
              @trigger-clicked-with-message="saveProfessionalAndSendMessage"
              @trigger-clicked-without-message="saveProfessionalAndDoNotSendMessage"
              />

          </section>
          </div>
          </div>
          
          
        </div>
      </panel>
      
      <panel
        id="panel-1"
        name="COORDONNÉES"
        :withTitle="false"
        >
        <div>

          <panel-contact-detail
            v-model="proFormData.professional"
            :is-professional="isProfessional"
            :form-errors-up="proFormErrorsValue"
            :form-errors-messages-up="proFormErrorsMessagesValue"
            ref="panelContactDetail"
            v-on:allow-to-use-bap="switchAllowToUseBap"
            />
          
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6 medium-offset-6">
              <trigger-buttons
                object-type="professional"
                :pk="proFormData.professional.pk"
                :triggers="proFormData.professional.triggers"
                @trigger-clicked="saveProfessional"
                @trigger-clicked-with-message="saveProfessionalAndSendMessage"
                @trigger-clicked-without-message="saveProfessionalAndDoNotSendMessage"
                />
            </div>
          </div>
          
        </div>
      </panel>
      
      <panel
        v-if="proFormData.professional.pk"
        id="panel-2"
        name="PRÉSENTATION"
        :withTitle="false"
        >
        <div>

          <panel-presentation
            v-if="proFormData !== null && proFormData.presentation !== null"
            v-model="proFormData.presentation"
            ref="panelPresentation"
            v-on:reload-presentation="load"
            />
          
        </div>
      </panel>
      
      <panel
        v-if="proFormData !== null && proFormData.professional !== null && ['generic', 'bank'].includes(proFormData.professional.formType) && proFormData.professional.pk"
        id="panel-3"
        :name="panel3Name"
        :withTitle="false"
        >
        <div>

          <reference-list
            :references="proFormData.references"
            :form-type="formType"
            :additional-addresses="proFormData.professional.additionalAddresses"
            v-on:edit-reference="editReference"
            v-on:reload-references="load"
            />

        </div>
      </panel>
      
      
      <panel
        v-if="proFormData !== null"
        id="panel-4"
        name="COMPTE UTILISATEUR"
        :withTitle="false"
        >
        <div>
          <user-form
            :user="proFormData.user"
            :isProfessional="isProfessional"
            />
        </div>
      </panel>
      
    </panels>

  </layout-container>
  
</div>
</template>

<script>
import _ from 'lodash';

import ActionLink from '@/components/action_link';
import CheckboxField from '@/components/checkbox_field';
import LayoutContainer from '@/components/layout_container';
import Panel from '@/components/panel';
import History from '@/apps/listepro/professional_form/history';
import NewslettersRecipient from '@/apps/listepro/professional_form/newsletters_recipient';
import Note from '@/apps/listepro/professional_form/note';
import PanelContactDetail from '@/apps/listepro/professional_form/panel_contact_detail';
import PanelPresentation from '@/apps/listepro/professional_form/panel_presentation';
import ReferenceList from '@/apps/listepro/professional_form/reference_list';
import Panels from '@/components/panels';
import RegularButton from '@/components/regular_button';
import SelectReferents from '@/apps/listepro/select_referents';
import Spinner from '@/components/spinner';
import TriggerButtons from '@/apps/listepro/professional_form/trigger_buttons';
import UserForm from '@/apps/listepro/professional_form/user_form';
import apiClient from '@/api/api';
import confirmActionMixin from '@/mixins/confirm_action_mixin';
import professionalMixin from '@/apps/listepro/professional_form/professional_mixin';

import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import { userMixin } from '@/mixins/user';

import Vue, { computed } from 'vue';



export default {
    name: 'ProfessionalForm',
    mixins: [ professionalMixin, handleFormErrorsMixin, confirmActionMixin, userMixin ],
    provide() {
        return {
            formErrors: computed(() => this.proFormErrors.value),
            formErrorsMessages: computed(() => this.proFormErrorsMessages.value),
            reloadProfessional: this.load,
            userPk: this.pk || this.user.pk,
        };
    },
    props: {
        pk: {type: [String, Number], default: null},
    },
    components: {
        ActionLink,
        CheckboxField,
        History,
        Note,
        LayoutContainer,
        NewslettersRecipient,
        Panel,
        PanelContactDetail,
        PanelPresentation,
        Panels,
        ReferenceList,
        RegularButton,
        SelectReferents,
        Spinner,
        TriggerButtons,
        UserForm,
    },
    data() {
        return {
            proFormData: null,
            editedReference: null, 
            isLoading: false,
            referents: [],
            referentsLoaded: false,
            proFormErrors: { value: [] },
            proFormErrorsMessages: { value: [] },
            allowToUseBap: true,
        };
    },
    computed: {
        proFormErrorsValue() {
            return this.proFormErrors.value;
        },
        proFormErrorsMessagesValue() {
            return this.proFormErrorsMessages.value;
        },
        subscribedToNewsletter() {
            return this.proFormData?.professional?.subscribedToNewsletter;
        },
        showBapConfig() {
            return this.proFormData?.professional?.formType !== 'real_estate_agency' && this.allowToUseBap;
        },
        isProfessional() {
            return this.userUtilities.isProfessional;
        },
        referencesLabels() {
            const labels = {};
            if (this.proFormData) {
                for (const ref of this.proFormData.references) {
                    if (labels[ref.publicationStatusAsLabel] === undefined) {
                        labels[ref.publicationStatusAsLabel] = {state: ref.transitionState, count: 1};
                    } else {
                        labels[ref.publicationStatusAsLabel].count += 1;
                    }
                }
            }
            return labels;
        },
        referencesStatus() {
            const labels = {};
            if (this.proFormData) {
                for (const ref of this.proFormData.references) {
                    if (labels[ref.statusAsLabel] === undefined) {
                        labels[ref.statusAsLabel] = {state: ref.transitionState, count: 1};
                    } else {
                        labels[ref.statusAsLabel].count += 1;
                    }
                }
            }
            return labels;
        },
        formType() {
            try {
                return this.proFormData?.professional?.formType;
            } catch {
                return 'unknown';
            }
        },
        panel3Name() {
            const names = {
                'generic': 'RÉALISATIONS',
                'bank': 'OFFRES',
            };
            try {
                return names[this.proFormData?.professional?.formType];
            } catch {
                return 'TBD';
            }
        },
        referentsOptions() {
            // const entity = this.contact || this.organization;
            // const currentReferents = entity.referents.map(e => e.value);
            const options = this.referents.filter(
                ({ isActive, pk }) => isActive // || currentReferents.includes(pk)
            ).map(({ groupName, firstName, lastName, email, pk }) => ({
                label: `${firstName} ${lastName} (${email}) - ${groupName}`,
                value: pk,
                pk: pk,
            }));
            options.sort();
            return options;
        },
    },
    watch: {
        subscribedToNewsletter(value) {
            if (value) {
                this.$refs.newslettersRecipient.addEmailIfEmpty(this.proFormData.user.email);
            }
        },
    },
    
    mounted: function() {
        this.loadReferents();
        this.load();
    },
    
    methods: {
        switchAllowToUseBap(value) {
            this.allowToUseBap = value;
            if (!this.allowToUseBap) {
                this.proFormData.professional.allowToRegisterToBap = false;
            }
        },
        panelChanged(id) {
            if (id === 'panel-1') {
                this.$refs.panelContactDetail.panelDisplayed();
            }
        },
        resetErrors() {
            this.resetErrorFields();
            this.proFormErrors = { value: []};
            this.proFormErrorsMessages = { value: []};
        },
        
        load() {
            const pk = this.isProfessional ? this.user.pk : this.pk ;
            apiClient
                .genericDetail('/listepro/professional-form-admin-by-user/', pk, true)
                .then(response => {
                    this.proFormData = _.cloneDeep(response);
                    if (this.proFormData.presentation === null && this.proFormData.professional === null) {
                    
                        this.proFormData = {
                            "notes": [],
                            "presentation": {
                                "pk": null,
                                "id": null,
                                "transitionState": "initial",
                                "transitionHistory": {
                                    "history": [],
                                },
                                "professional": null,
                                "presentationPublished": {
                                    "pk": null,
                                    "id": null,
                                    "activities": [],
                                    "description": "",
                                    "personalKeyWords": "",
                                    "segments": [],
                                    "primaryKeyWords": [],
                                    "secondaryKeyWords": [],
                                    "subMissions": [],
                                },
                                "presentationDraft": {
                                    "pk": null,
                                    "id": null,
                                    "activities": [],
                                    "description": "",
                                    "personalKeyWords": "",
                                    "segments": [],
                                    "primaryKeyWords": [],
                                    "secondaryKeyWords": [],
                                    "subMissions": [],
                                },
                                "publicationStatusAsLabel": null,
                                "statusAsLabel": null,
                                "differencesBetweenDraftAndPublished": [],
                                "triggers": {
                                    "fill": {
                                        "label": "Remplir",
                                        "roles": [
                                            "professional", "superadvisor", "advisor", "listeproadmin",
                                        ],
                                    },
                                },
                                "isValidationPending": false,
                            },
                            "professional": {
                                "pk": null,
                                "id": null,
                                "createdAt": null,
                                "updatedAt": null,
                                "transitionState": "initial",
                                "transitionHistory": {
                                },
                                "name": "",
                                "address": "",
                                "town": "",
                                "postcode": "",
                                "phoneNumber": "",
                                "email": "",
                                "url": "",
                                "logo": null,
                                "originalLogo": null,
                                "job": null,
                                "geom": {
                                    lat: 0,
                                    lon: 0,
                                },
                                "user": this.proFormData.user.pk,
                                "canUseMultipleAddresses": false,
                                "allowToRegisterToBap": false,
                                "referents": [],
                                "additionalAddresses": [],
                                "publicationStatusAsLabel": "Initié",
                                "statusAsLabel": "Initié",
                                "triggers": {
                                    "fill": {
                                        "label": "Remplir",
                                        "roles": [
                                            "professional", "superadvisor", "advisor", "listeproadmin",
                                        ],
                                    },
                                },
                                "formType": "generic",
                                "isValidationPending": false,
                            },
                            "references": [],
                            "user": this.proFormData.user,
                        };
                    }
                })
                .catch(error => {
                    this.handleFormErrors(error);
                });

            this.resetErrors(); 
        },
        saveProfessional(action) {
            this.resetErrors();
            this.proFormData.professional.executeAction = action;
            apiClient
                .genericSave('/listepro/professional/', this.proFormData.professional)
                .then(response => {
                    if (this.proFormData.professional.subscribedToNewsletter) {
                        apiClient
                            .genericPost('/listepro/newsletters-recipient/', {
                                professional: this.proFormData.professional.pk,
                                emails: this.proFormData.newslettersRecipients,
                            })
                            .then(response => {
                                this.notifyOkAndReload();
                            })
                            .catch(error => {
                                this.notifyError(error);
                                Vue.set(this.proFormErrors, 'value', this.formErrors);
                                Vue.set(this.proFormErrorsMessages, 'value', this.formErrorsMessages);
                            });
                                
                    } else {
                        this.notifyOkAndReload();
                    }
                    
                })
                .catch(error => {
                    this.notifyError(error);
                });
        },

        notifyError(error) {
            this.handleFormErrors(error);
            Vue.set(this.proFormErrors, 'value', this.formErrors);
            Vue.set(this.proFormErrorsMessages, 'value', this.formErrorsMessages);
        },
        
        notifyOkAndReload() {
            this.$notify({
                group: 'root',
                type: 'success',
                title: 'Modifications enregistrées',
            });
            this.load();
        },
        
        saveProfessionalAndSendMessage(message) {

            this.resetErrors();
            this.proFormData.professional.executeAction = message.action;
            this.proFormData.professional.executeMailSubject = message.subject;
            this.proFormData.professional.executeMailContent = message.content;

            this.saveProfessional(message.action);          
        },

        saveProfessionalAndDoNotSendMessage(message) {

            this.resetErrors();
            this.proFormData.professional.executeAction = message.action;
            this.proFormData.professional.executeNoMail = true;

            this.saveProfessional(message.action);          
        },

        routerBack() {
            this.$router.push({name: "ListeProAdminProfessionalList"});
        },
        editReference(pk) {
            this.editedReference = this.proFormData.references.find(x => x.pk === pk);
        },
        loadReferents() {
            apiClient.genericList('listepro/advisor/')
                .then(advisors => {
                    this.referents = advisors;
                    this.referentsLoaded = true;
                });
        },
        openProfessionalInNewTab() {
            const routeData = this.$router.resolve({name: 'ListeProPublicDetail', params: {pk: this.proFormData.professional.pk}});
            window.open(routeData.href, '_blank');
        },
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'
@require '../../../stylesheet/listepro_font'

.professional-form
    .capitalize
        text-transform: capitalize

    .content
        background-color: gray-100

        .professional-name
            h2
                margin-top: unset
                text-transform: uppercase

    .back
        border-color: primary-color-500
        background-color: #fff
        border: 0.2rem solid
        color: primary-color-500

    table.states
        .professional
            .initial
                color: body-font-color

            .draft
                color: body-font-color

            .in_annual_validation
                color: warning-color
                font-weight: bold

            .in_validation
                color: warning-color
                font-weight: bold

            .probationary
                color: body-font-color
                font-weight: bold

            .probationary_new
                color: body-font-color

            .published
                color: body-font-color

            .unpublished
                color: body-font-color

            .closed
                color: body-font-color

        .presentation
            .initial
                color: body-font-color

            .draft
                color: body-font-color

            .in_validation
                color: warning-color
                font-weight: bold

            .published
                color: body-font-color

            .published_with_draft
                color: body-font-color

            .published_with_draft_in_validation
                color: warning-color
                font-weight: bold

            .unpublished
                color: body-font-color

            .closed
                color: body-font-color

        .references
            .initial
                color: body-font-color

            .draft
                color: body-font-color

            .in_validation
                color: warning-color
                font-weight: bold

            .published
                color: body-font-color

            .published_with_draft
                color: body-font-color

            .published_with_draft_in_validation
                color: warning-color
                font-weight: bold

            .unpublished
                color: body-font-color

            .closed
                color: body-font-color

    :deep(.title)
    :deep(h2)
        font-weight: bold
        margin-top: 3rem

    :deep(.subtitle)
    :deep(h3)
        font-weight: bold
        font-size: 1rem
</style>
