
import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

// Solid
import {
    faAddressCard as fasAddressCard,
    faAlignLeft as fasAlignLeft,
    faAlignRight as fasAlignRight,
    faAlignCenter as fasAlignCenter,
    faAlignJustify as fasAlignJustify,
    faAngleDoubleRight as fasAngleDoubleRight,
    faAngleDown as fasAngleDown,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faAngleUp as fasAngleUp,
    faArrowAltCircleRight as fasArrowAltCircleRight,
    faArrowDown as fasArrowDown,
    faArrowRight as fasArrowRight,
    faArrows as fasArrows,
    faArrowsAltV as fasArrowsAltV,
    faArrowToLeft as fasArrowToLeft,
    faArrowToRight as fasArrowToRight,
    faAsterisk as fasAsterisk,
    faAt as fasAt,
    faBaby as fasBaby,
    faBackspace as fasBackspace,
    faBadgeCheck as fasBadgeCheck,
    faBan as fasBan,
    faBars as fasBars,
    faBell as fasBell,
    faBlender as fasBlender,
    faBlenderPhone as fasBlenderPhone,
    faBold as fasBold,
    faBolt as fasBolt,
    faBomb as fasBomb,
    faBoxCheck as fasBoxCheck,
    faBoxOpen as fasBoxOpen,
    faBuilding as fasBuilding,
    faBullseyePointer as fasBullseyePointer,
    faBurn as fasBurn,
    faBicycle as fasBicycle,
    faCalculator as fasCalculator,
    faCalendarAlt as fasCalendarAlt,
    faCalendarCheck as fasCalendarCheck,
    faCalendarDay as fasCalendarDay,
    faCarBus as fasCarBus,
    faCaretLeft as fasCaretLeft,
    faCaretRight as fasCaretRight,
    faChalkboardTeacher as fasChalkboardTeacher,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faCheckSquare as fasCheckSquare,
    faChevronCircleDown as fasChevronCircleDown,
    faChevronCircleLeft as fasChevronCircleLeft,
    faChevronCircleRight as fasChevronCircleRight,
    faChevronDoubleRight as fasChevronDoubleRight,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faChevronSquareRight as fasChevronSquareRight,
    faChild as fasChild,
    faCircle as fasCircle,
    faCity as fasCity,
    faClipboardList as fasClipboardList,
    faClock as fasClock,
    faClone as fasClone,
    faCloud as fasCloud,
    faCode as fasCode,
    faCoins as fasCoins,
    faColumns as fasColumns,
    faComment as fasComment,
    faCommentSlash as fasCommentSlash,
    faCommentAltLines as fasCommentAltLines,
    faCommentDots as fasCommentDots,
    faComments as fasComments,
    faCopy as fasCopy,
    faDiceOne as fasDiceOne,
    faDiceTwo as fasDiceTwo,
    faDigging as fasDigging,
    faDownload as fasDownload,
    faEdit as fasEdit,
    faEnvelope as fasEnvelope,
    faEnvelopeOpen as fasEnvelopeOpen,
    faEnvelopeOpenText as fasEnvelopeOpenText,
    faEquals as fasEquals,
    faEuroSign as fasEuroSign,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faExpandArrows as fasExpandArrows,
    faExternalLink as fasExternalLink,
    faEye as fasEye,
    faEyeSlash as fasEyeSlash,
    faFile as fasFile,
    faFileAlt as fasFileAlt,
    faFileCertificate as fasFileCertificate,
    faFileCheck as fasFileCheck,
    faFileExport as fasFileExport,
    faFileImport as fasFileImport,
    faFilePdf as fasFilePdf,
    faFilter as fasFilter,
    faFingerprint as fasFingerprint,
    faFlag as fasFlag,
    faFolderOpen as fasFolderOpen,
    faGasPump as fasGasPump,
    faGlobe as fasGlobe,
    faGlobeEurope as fasGlobeEurope,
    faGripVertical as fasGripVertical,
    faH1 as fasH1,
    faH2 as fasH2,
    faH3 as fasH3,
    faHandHolding as fasHandHolding,
    faHandshake as fasHandshake,
    faHardHat as fasHardHat,
    faHeadset as fasHeadset,
    faHeat as fasHeat,
    faHistory as fasHistory,
    faHome as fasHome,
    faHomeLg as fasHomeLg,
    faHorizontalRule as fasHorizontalRule,
    faHouseLeave as fasHouseLeave,
    faHouseUser as fasHouseUser,
    faIdBadge as fasIdBadge,
    faIdCard as fasIdCard,
    faImage as fasImage,
    faInboxIn as fasInboxIn,
    faInboxOut as fasInboxOut,
    faIndustryAlt as fasIndustryAlt,
    faInfo as fasInfo,
    faInfoCircle as fasInfoCircle,
    faItalic as fasItalic,
    faKey as fasKey,
    faLayerGroup as fasLayerGroup,
    faLink as fasLink,
    faList as fasList,
    faListOl as fasListOl,
    faListUl as fasListUl,
    faLockAlt as fasLockAlt,
    faLockOpenAlt as fasLockOpenAlt,
    faLongArrowAltRight as fasLongArrowAltRight,
    faMagic as fasMagic,
    faMailbox as fasMailbox,
    faMailBulk as fasMailBulk,
    faMap as fasMap,
    faMapMarkedAlt as fasMapMarkedAlt,
    faMapMarker as fasMapMarker,
    faMapMarkerAlt as fasMapMarkerAlt,
    faMapMarkerSlash as fasMapMarkerSlash,
    faMinusSquare as fasMinusSquare,
    faMobileAlt as fasMobileAlt,
    faNetworkWired as fasNetworkWired,
    faObjectGroup as fasObjectGroup,
    faParagraph as fasParagraph,
    faParking as fasParking,
    faPencil as fasPencil,
    faPencilAlt as fasPencilAlt,
    faPercentage as fasPercentage,
    faPhone as fasPhone,
    faPhoneAlt as fasPhoneAlt,
    faPhoneLaptop as fasPhoneLaptop,
    faPhonePlus as fasPhonePlus,
    faPhoneSquare as fasPhoneSquare,
    faPiggyBank as fasPiggyBank,
    faPlay as fasPlay,
    faPlus as fasPlus,
    faPlusCircle as fasPlusCircle,
    faPlusSquare as fasPlusSquare,
    faPowerOff as fasPowerOff,
    faProjectDiagram as fasProjectDiagram,
    faQuestion as fasQuestion,
    faQuestionCircle as fasQuestionCircle,
    faQuoteRight as fasQuoteRight,
    faRedo as fasRedo,
    faRepeat as fasRepeat,
    faRulerHorizontal as fasRulerHorizontal,
    faSack as fasSack,
    faSackDollar as fasSackDollar,
    faSave as fasSave,
    faScrubber as fasScrubber,
    faSearch as fasSearch,
    faSearchMinus as fasSearchMinus,
    faSearchPlus as fasSearchPlus,
    faServer as fasServer,
    faShareAlt as fasShareAlt,
    faShoePrints as fasShoePrints,
    faShower as fasShower,
    faSignature as fasSignature,
    faSignIn as fasSignIn,
    faSignInAlt as fasSignInAlt,
    faSignOut as fasSignOut,
    faSmog as fasSmog,
    faSms as fasSms,
    faSolarPanel as fasSolarPanel,
    faSortAmountDownAlt as fasSortAmountDownAlt,
    faSortAmountUpAlt as fasSortAmountUpAlt,
    faSparkles as fasSparkles,
    faSpinner as fasSpinner,
    faSpinnerThird as fasSpinnerThird,
    faSquare as fasSquare,
    faSquareFull as fasSquareFull,
    faStar as fasStar,
    faStop as fasStop,
    faStreetView as fasStreetView,
    faStrikethrough as fasStrikethrough,
    faSun as fasSun,
    faSunCloud as fasSunCloud,
    faSyncAlt as fasSyncAlt,
    faTable as fasTable,
    faTag as fasTag,
    faTally as fasTally,
    faTasksAlt as fasTasksAlt,
    faTextHeight as fasTextHeight,
    faThermometerHalf as fasThermometerHalf,
    faThLarge as fasThLarge,
    faThumbtack as fasThumbtack,
    faTimes as fasTimes,
    faTimesCircle as fasTimesCircle,
    faTimesSquare as fasTimesSquare,
    faTint as fasTint,
    faTools as fasTools,
    faTrash as fasTrash,
    faTrashAlt as fasTrashAlt,
    faTreasureChest as fasTreasureChest,
    faTree as fasTree,
    faTriangle as fasTriangle,
    faUnderline as fasUnderline,
    faUndo as fasUndo,
    faUndoAlt as fasUndoAlt,
    faUnlink as fasUnlink,
    faUnlock as fasUnlock,
    faUnlockAlt as fasUnlockAlt,
    faUser as fasUser,
    faUserCircle as fasUserCircle,
    faUserCog as fasUserCog,
    faUserCrown as fasUserCrown,
    faUserHardHat as fasUserHardHat,
    faUserPlus as fasUserPlus,
    faUsers as fasUsers,
    faWarehouse as fasWarehouse,
    faWindTurbine as fasWindTurbine,
    faWineBottle as fasWineBottle,
} from '@fortawesome/pro-solid-svg-icons';

// Regular
import {
    faAngleDown as farAngleDown,
    faAsterisk as farAsterisk,
    faBell as farBell,
    faBuilding as farBuilding,
    faCalendarAlt as farCalendarAlt,
    faCalendarCheck as farCalendarCheck,
    faCalendarDay as farCalendarDay,
    faCalendarPlus as farCalendarPlus,
    faChalkboard as farChalkboard,
    faChartArea as farChartArea,
    faCheck as farCheck,
    faCheckCircle as farCheckCircle,
    faCircle as farCircle,
    faCity as farCity,
    faClipboard as farClipboard,
    faClipboardList as farClipboardList,
    faClock as farClock,
    faClone as farClone,
    faCloudUpload as farCloudUpload,
    faCog as farCog,
    faComment as farComment,
    faCommentDots as farCommentDots,
    faComments as farComments,
    faExchangeAlt as farExchangeAlt,
    faExclamationCircle as farExclamationCircle,
    faExclamationTriangle as farExclamationTriangle,
    faEye as farEye,
    faEyeSlash as farEyeSlash,
    faFile as farFile,
    faFileAlt as farFileAlt,
    faFileCertificate as farFileCertificate,
    faFileSignature as farFileSignature,
    faFire as farFire,
    faFireplace as farFireplace,
    faFolderOpen as farFolderOpen,
    faGlobeEurope as farGlobeEurope,
    faGripLinesVertical as farGripLinesVertical,
    faGripVertical as farGripVertical,
    faHandshake as farHandshake,
    faHome as farHome,
    faHomeLg as farHomeLg,
    faIdCard as farIdCard,
    faIndustryAlt as farIndustryAlt,
    faInfoCircle as farInfoCircle,
    faJoystick as farJoystick,
    faKey as farKey,
    faLink as farLink,
    faList as farList,
    faListUl as farListUl,
    faLockAlt as farLockAlt,
    faMailBulk as farMailBulk,
    faMapMarkedAlt as farMapMarkedAlt,
    faMapMarkerAlt as farMapMarkerAlt,
    faNetworkWired as farNetworkWired,
    faNewspaper as farNewspaper,
    faNotEqual as farNotEqual,
    faPen as farPen,
    faPencil as farPencil,
    faPencilRuler as farPencilRuler,
    faPiggyBank as farPiggyBank,
    faPlus as farPlus,
    faQuestionCircle as farQuestionCircle,
    faRedoAlt as farRedoAlt,
    faSave as farSave,
    faSearch as farSearch,
    faShareAlt as farShareAlt,
    faSignIn as farSignIn,
    faSms as farSms,
    faSquare as farSquare,
    faThList as farThList,
    faThermometerHalf as farThermometerHalf,
    faTools as farTools,
    faTreasureChest as farTreasureChest,
    faUmbrellaBeach as farUmbrellaBeach,
    faUser as farUser,
    faUserCircle as farUserCircle,
    faUserHardHat as farUserHardHat,
    faUserLock as farUserLock,
    faUsers as farUsers,
    faUsersClass as farUsersClass,
    faUsersCog as farUsersCog,
    faWarehouse as farWarehouse,
} from '@fortawesome/pro-regular-svg-icons';

// Light
import {
    faAngleDoubleLeft as falAngleDoubleLeft,
    faAngleDoubleRight as falAngleDoubleRight,
    faBlender as falBlender,
    faBuilding as falBuilding,
    faBurn as falBurn,
    faCalendarAlt as falCalendarAlt,
    faCalendarCheck as falCalendarCheck,
    faCalendarPlus as falCalendarPlus,
    faChairOffice as falChairOffice,
    faCity as falCity,
    faDollyFlatbedAlt as falDollyFlatbedAlt,
    faEuroSign as falEuroSign,
    faFileAlt as falFileAlt,
    faFilePdf as falFilePdf,
    faFilter as falFilter,
    faFrown as falFrown,
    faGrin as falGrin,
    faGrinBeam as falGrinBeam,
    faHandshake as falHandshake,
    faHardHat as falHardHat,
    faHomeLg as falHomeLg,
    faHourglassHalf as falHourglassHalf,
    faHouse as falHouse,
    faKey as falKey,
    faListAlt as falListAlt,
    faMeh as falMeh,
    faPaperclip as falPaperclip,
    faPenFancy as falPenFancy,
    faPiggyBank as falPiggyBank,
    faQuestionCircle as falQuestionCircle,
    faShield as falShield,
    faSigma as falSigma,
    faSmile as falSmile,
    faSmileBeam as falSmileBeam,
    faStar as falStar,
    faTemperatureHot as falTemperatureHot,
    faTimes as falTimes,
    faTimesCircle as falTimesCircle,
    faUser as falUser,
    faUserHardHat as falUserHardHat,
    faWarehouse as falWarehouse,
    faWrench as falWrench,

} from '@fortawesome/pro-light-svg-icons';

export default function loadFontAwesomeIcons() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

    library.add(
        // Solid
        fasAddressCard,
        fasAlignLeft,
        fasAlignRight,
        fasAlignCenter,
        fasAlignJustify,
        fasAngleDoubleRight,
        fasAngleDown,
        fasAngleLeft,
        fasAngleRight,
        fasAngleUp,
        fasArrowAltCircleRight,
        fasArrowDown,
        fasArrowRight,
        fasArrows,
        fasArrowsAltV,
        fasArrowToLeft,
        fasArrowToRight,
        fasAsterisk,
        fasAt,
        fasBaby,
        fasBackspace,
        fasBadgeCheck,
        fasBan,
        fasBars,
        fasBell,
        fasBlender,
        fasBlenderPhone,
        fasBold,
        fasBolt,
        fasBomb,
        fasBoxCheck,
        fasBoxOpen,
        fasBuilding,
        fasBullseyePointer,
        fasBurn,
        fasBicycle,
        fasCalculator,
        fasCalendarAlt,
        fasCalendarCheck,
        fasCalendarDay,
        fasCarBus,
        fasCaretLeft,
        fasCaretRight,
        fasChalkboardTeacher,
        fasCheck,
        fasCheckCircle,
        fasCheckSquare,
        fasChevronCircleDown,
        fasChevronCircleLeft,
        fasChevronCircleRight,
        fasChevronDoubleRight,
        fasChevronLeft,
        fasChevronRight,
        fasChevronSquareRight,
        fasChild,
        fasCircle,
        fasCity,
        fasClipboardList,
        fasClock,
        fasClone,
        fasCloud,
        fasCode,
        fasCoins,
        fasColumns,
        fasComment,
        fasCommentSlash,
        fasCommentAltLines,
        fasCommentDots,
        fasComments,
        fasCopy,
        fasDiceOne,
        fasDiceTwo,
        fasDigging,
        fasDownload,
        fasEdit,
        fasEnvelope,
        fasEnvelopeOpen,
        fasEnvelopeOpenText,
        fasEquals,
        fasEuroSign,
        fasExclamationCircle,
        fasExclamationTriangle,
        fasExpandArrows,
        fasExternalLink,
        fasEye,
        fasEyeSlash,
        fasFile,
        fasFileAlt,
        fasFileCertificate,
        fasFileCheck,
        fasFileExport,
        fasFileImport,
        fasFilePdf,
        fasFilter,
        fasFingerprint,
        fasFlag,
        fasFolderOpen,
        fasGasPump,
        fasGlobe,
        fasGlobeEurope,
        fasGripVertical,
        fasH1,
        fasH2,
        fasH3,
        fasHandHolding,
        fasHandshake,
        fasHardHat,
        fasHeadset,
        fasHeat,
        fasHistory,
        fasHome,
        fasHomeLg,
        fasHorizontalRule,
        fasHouseLeave,
        fasHouseUser,
        fasIdBadge,
        fasIdCard,
        fasImage,
        fasInboxIn,
        fasInboxOut,
        fasIndustryAlt,
        fasInfo,
        fasInfoCircle,
        fasItalic,
        fasKey,
        fasLayerGroup,
        fasLink,
        fasList,
        fasListOl,
        fasListUl,
        fasLockAlt,
        fasLockOpenAlt,
        fasLongArrowAltRight,
        fasMagic,
        fasMailbox,
        fasMailBulk,
        fasMap,
        fasMapMarkedAlt,
        fasMapMarker,
        fasMapMarkerAlt,
        fasMapMarkerSlash,
        fasMinusSquare,
        fasMobileAlt,
        fasNetworkWired,
        fasObjectGroup,
        fasParagraph,
        fasParking,
        fasPencil,
        fasPencilAlt,
        fasPercentage,
        fasPhone,
        fasPhoneAlt,
        fasPhoneLaptop,
        fasPhonePlus,
        fasPhoneSquare,
        fasPiggyBank,
        fasPlay,
        fasPlus,
        fasPlusCircle,
        fasPlusSquare,
        fasPowerOff,
        fasProjectDiagram,
        fasQuestion,
        fasQuestionCircle,
        fasQuoteRight,
        fasRedo,
        fasRepeat,
        fasRulerHorizontal,
        fasSack,
        fasSackDollar,
        fasSave,
        fasScrubber,
        fasSearch,
        fasSearchMinus,
        fasSearchPlus,
        fasServer,
        fasShareAlt,
        fasShoePrints,
        fasShower,
        fasSignature,
        fasSignIn,
        fasSignInAlt,
        fasSignOut,
        fasSmog,
        fasSms,
        fasSolarPanel,
        fasSortAmountDownAlt,
        fasSortAmountUpAlt,
        fasSparkles,
        fasSpinner,
        fasSpinnerThird,
        fasSquare,
        fasSquareFull,
        fasStar,
        fasStop,
        fasStreetView,
        fasStrikethrough,
        fasSun,
        fasSunCloud,
        fasSyncAlt,
        fasTable,
        fasTag,
        fasTally,
        fasTasksAlt,
        fasTextHeight,
        fasThermometerHalf,
        fasThLarge,
        fasThumbtack,
        fasTimes,
        fasTimesCircle,
        fasTimesSquare,
        fasTint,
        fasTools,
        fasTrash,
        fasTrashAlt,
        fasTreasureChest,
        fasTree,
        fasTriangle,
        fasUnderline,
        fasUndo,
        fasUndoAlt,
        fasUnlink,
        fasUnlock,
        fasUnlockAlt,
        fasUser,
        fasUserCircle,
        fasUserCog,
        fasUserCrown,
        fasUserHardHat,
        fasUserPlus,
        fasUsers,
        fasWarehouse,
        fasWindTurbine,
        fasWineBottle,

        // Regular
        farAngleDown,
        farAsterisk,
        farBell,
        farBuilding,
        farCalendarAlt,
        farCalendarCheck,
        farCalendarDay,
        farCalendarPlus,
        farChalkboard,
        farChartArea,
        farCheck,
        farCheckCircle,
        farCircle,
        farCity,
        farClipboard,
        farClipboardList,
        farClock,
        farClone,
        farCloudUpload,
        farCog,
        farComment,
        farCommentDots,
        farComments,
        farExchangeAlt,
        farExclamationCircle,
        farExclamationTriangle,
        farEye,
        farEyeSlash,
        farFile,
        farFileAlt,
        farFileCertificate,
        farFileSignature,
        farFire,
        farFireplace,
        farFolderOpen,
        farGlobeEurope,
        farGripLinesVertical,
        farGripVertical,
        farHandshake,
        farHome,
        farHomeLg,
        farIdCard,
        farIndustryAlt,
        farInfoCircle,
        farJoystick,
        farKey,
        farLink,
        farList,
        farListUl,
        farLockAlt,
        farMailBulk,
        farMapMarkedAlt,
        farMapMarkerAlt,
        farNetworkWired,
        farNewspaper,
        farNotEqual,
        farPen,
        farPencil,
        farPencilRuler,
        farPiggyBank,
        farPlus,
        farQuestionCircle,
        farRedoAlt,
        farSave,
        farSearch,
        farShareAlt,
        farSignIn,
        farSms,
        farSquare,
        farThList,
        farThermometerHalf,
        farTools,
        farTreasureChest,
        farUmbrellaBeach,
        farUser,
        farUserCircle,
        farUserHardHat,
        farUserLock,
        farUsers,
        farUsersClass,
        farUsersCog,
        farWarehouse,

        // Light
        falAngleDoubleLeft,
        falAngleDoubleRight,
        falBlender,
        falBuilding,
        falBurn,
        falCalendarAlt,
        falCalendarCheck,
        falCalendarPlus,
        falChairOffice,
        falCity,
        falDollyFlatbedAlt,
        falEuroSign,
        falFileAlt,
        falFilePdf,
        falFilter,
        falFrown,
        falGrin,
        falGrinBeam,
        falHandshake,
        falHardHat,
        falHomeLg,
        falHourglassHalf,
        falHouse,
        falListAlt,
        falKey,
        falMeh,
        falPaperclip,
        falPenFancy,
        falPiggyBank,
        falQuestionCircle,
        falShield,
        falSigma,
        falSmile,
        falSmileBeam,
        falStar,
        falTemperatureHot,
        falTimes,
        falTimesCircle,
        falUser,
        falUserHardHat,
        falWarehouse,
        falWrench,
    );
}
