<template functional>
<component
   :is="injections.components.VueGoodTable"
   :search-options="props.searchOptions"
   :pagination-options="props.paginationOptions"
   v-bind="data.attrs"
   v-on="listeners"
   >
  <template v-slot:table-header-row="props">
      <slot name="table-header-row" v-bind="props">
      </slot>
  </template>
  <template v-slot:table-row="props">
      <slot name="table-row" v-bind="props">
      </slot>
  </template>
  
  <div slot="table-actions-bottom"></div>
</component>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
    props: {
        searchOptions: { default: () => { return { enabled: true }; } },
        paginationOptions: {
            default: () => {
                return {
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    perPageDropdown: [10, 25, 200],
                    dropdownAllowAll: true,
                    nextLabel: 'suivant',
                    prevLabel: 'précédent',
                    rowsPerPageLabel: 'Lignes par page',
                    ofLabel: 'de',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'Toutes',
                };
            },
        },
    },
    inject: {
        components: {
            default: {
                VueGoodTable,
            },
        },
    },
};
</script>

<style lang="stylus">
@require '../stylesheet/variables'
@require '../stylesheet/typography'

.vgt-wrap
    margin-bottom: space-lg

    .vgt-inner-wrap
        button.disabled
        button.disabled:hover
            background-color: unset

        .vgt-table
            .column-center
                text-align: center !important

select.footer__row-count__select
    background-image: none
</style>
